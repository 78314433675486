import React from "react";

const GalleryFilter = (props) => {
  const { value, onClick, activeFilter } = props;

  return (
    <button
      className={value === activeFilter ? "active" : ""}
      onClick={() => {
        onClick(value);
      }}
    >
      {props.children}
    </button>
  );
};

export default GalleryFilter;
